import { useAudioPlayer, useGlobalAudioPlayer } from "react-use-audio-player";
import { useEffect } from "react";
import { AiOutlinePause } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import { Box, Square } from "@chakra-ui/react";
// import { Howl } from "howler";

function MyPlayer({ file }) {
  const player = useGlobalAudioPlayer();
  const play_state = player.playing;

  console.log(play_state);
  // const [isPlaying, setIsPlaying] = useState(false);

  // const song = "https://dfkbrdbkwd97.cloudfront.net/Caught_In_The_Blues.mp3";

  const diff_song =
    "https://dfkbrdbkwd97.cloudfront.net/For_you_Songcard_Version.mp3";

  const third_song =
    "https://dfkbrdbkwd97.cloudfront.net/Faun_Stella_-_The_Isle__The_Sea_-_Limited_3_Edition_-_Meghaan_LeBlanc.mp3";

  const handleThing = (url) => {
    console.log("handled");
    console.log(url);
    player.load(url);
    // setIsPlaying(true)
    // player.load("https://dfkbrdbkwd97.cloudfront.net/Caught_In_The_Blues.mp3", {
    //         html5: true,
    //         format: "mp3",
    //         autoplay: true
    //     })
  };

  const handlePlayPause = () => {
    player.togglePlayPause();
  };

  const loadSong = (url) => {
    console.log("load song...");
    console.log(url);
    handleThing(url);
  };

  useEffect(() => {
    player.load(file, {
      html5: true,
      format: "mp3",
      autoplay: true
    });
  }, []);

  // console.log("rendered");

  // ... later in a callback, effect, etc.

  return (
    <>
      <Square
        size="70px"
        // bg="#161616"
        color="black"
        marginTop="7px"
        marginBottom="5px"
        borderStyle="dotted"
        borderWidth="1px"
        borderColor="black"
        onClick={() => handlePlayPause()}
      >
        {play_state ? (
          <>
            {/* <p>playing</p> */}
            <AiOutlinePause />
          </>
        ) : (
          <>
            {/* <p>paused</p> */}
            <BsFillPlayFill />
          </>
        )}
        {/* <button onClick={() => handleThing()}>click</button> */}
        {/* <button onClick={() => loadSong(diff_song)}>load</button> */}
        {/* <button >toggle</button> */}
        {/* <button onClick={() => loadSong(third_song)}>load third</button> */}
        {/* hello world */}
      </Square>
    </>
  );
}

export default MyPlayer;
