import React, { useEffect, useState } from "react";
import { Image, Box, Button, Divider } from "@chakra-ui/react";
import { Center } from "@chakra-ui/react";
import { VStack, StackDivider, Container } from "@chakra-ui/react";
import Profile from "./Profile";
// import PlayerBar from "./player/PlayerBar";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import MyPlayer from "./player/Player";

const App = () => {
  const [data, setData] = useState([]);
  const [nowPlaying, setNowPlaying] = useState("");

  const test_song = "https://dfkbrdbkwd97.cloudfront.net/3-Cold-Red-Rain.mp3"


  const getSongs = async () => {
    try {
      const response = await fetch(
        "https://service.songcards.io/collector-songs"
      );
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFile = (file) => {
    console.log("handlefile is " + file);
    setNowPlaying(file)
  }

  // useEffect(() => {
  //   getSongs();
  // }, []);

  return (
    <Container centerContent>
      <Center>
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
          align="stretch"
          mt={100}
        >
          <Image
            maxWidth={300}
            src="Songcards_B_Black_lo-res.png"
            alt="Dan Abramov"
            mb={20}
          />
          <Box>
            <p>Log in to listen to your collection</p>
          </Box>
          <Box>
            {/* <audio controls>
              <source
                src="https://dfkbrdbkwd97.cloudfront.net/Calliope_dreaming_-_Matt_Cahill.mp3"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio> */}
          </Box>
          {/* <Box>
            <Profile />
          </Box> */}
        </VStack>
        {/* <Button onClick={getSongs}>Fetch Songs</Button> */}
      </Center>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          Actions
        </MenuButton>
        <MenuList>
          {data.map((item) => (
            <div key={item.id}>
              <MenuItem
              onClick={() => {
                // handleMenu(data.song.id);
                handleFile(item.song_file);
              }}
              
              >{item.id}</MenuItem>
            </div>
          ))}

          {/* <MenuItem>Create a Copy</MenuItem>
    <MenuItem>Mark as Draft</MenuItem>
    <MenuItem>Delete</MenuItem>
    <MenuItem>Attend a Workshop</MenuItem> */}
        </MenuList>
      </Menu>
      {/* <PlayerBar file={nowPlaying} /> */}
      <Divider />
      <MyPlayer file={test_song}/>
    </Container>
  );
};

export default App;
